import { useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logIn } from "./authSlice";
import "./auth.css";

const Login = ({ isAuthenticated }) => {
  const valuesReset = {
    email: "",
    password: "",
  };

  const [user, setUser] = useState(valuesReset);
  // TODO: handle error messages through redux / asyncThunk
  const [error, setError] = useState("");
  const [message, setMessage] = useState(valuesReset);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    setMessage(valuesReset);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(logIn(user));
    navigate("/user/bar-list");
    setUser(valuesReset);
  };

  if (isAuthenticated) return <Navigate replace to="/user/bar-list" />;

  return (
    <div className="sign-up-card">
      <form
        className="sign-up-form"
        onSubmit={(e) => handleSubmit(e)}
        // autoComplete="off"
      >
        <div className="title">LOGIN</div>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          name="email"
          id="email"
          value={user.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="password">
          Password
          <span className="password-message">{message.password}</span>
        </label>
        <input
          type="password"
          name="password"
          id="password"
          value={user.password}
          onChange={handleChange}
          required
        />
        <div className="error-message">{error}</div>
        <button className="submit-button" type="submit">
          Login
        </button>
        <p className="login-signup-link">
          Not signed up yet? <Link to="../register">SIGN UP</Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
