import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../features/user/userSlice"
import barReducer from "../features/bar/barSlice"
import authReducer from "../features/auth/authSlice"

export default  configureStore({
  reducer: {
    user: userReducer,
    bar: barReducer,
    auth: authReducer
  },
});
