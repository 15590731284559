import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import Axios from "../../lib/Axios";

const initialState = {
  isLoading: false,
  member: {
    id: "",
    name: "",
    email: "",
    permissions: "",
  },
  barName: "",
  barProfile: {},
  cocktailList: [],
  team: [],
};

export const createNewCocktail = createAsyncThunk(
  "bar/createNewCocktail",
  async (newCocktailData) => {
    const { parentBar } = newCocktailData;
    try {
      await Axios.post(
        `/bar/${parentBar}/cocktails`,
        JSON.stringify(newCocktailData)
      );
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const updateCocktail = createAsyncThunk(
  "bar/updateCocktail",
  async ({ cocktailId, cocktailData }) => {
    try {
      const res = await Axios.put(
        `/bar/any/cocktails/${cocktailId}`,
        cocktailData
      );
      return res.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const fetchCocktailList = createAsyncThunk(
  "bar/fetchCocktailList",
  async (barId) => {
    try {
      const { data } = await Axios.get(`/bar/${barId}/cocktails`);
      return data.cocktails;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const fetchBarAndMemberInfo = createAsyncThunk(
  "bar/fetchBarAndMemberInfo",
  async ({ barId, userId }) => {
    try {
      const { data } = await Axios.get(`/bar/${barId}/members/${userId}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const fetchTeamMembers = createAsyncThunk(
  "bar/fetchTeamMembers",
  async (barId) => {
    try {
      const { data } = await Axios.get(`/bar/${barId}/members/`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const deleteBar = createAsyncThunk(
  "bar/deleteBar",
  async ({ barId, memberId }) => {
    try {
      const { data } = await Axios.delete(`/bar/${barId}/${memberId}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const barSlice = createSlice({
  name: "bar",
  initialState,
  // reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(fetchCocktailList.fulfilled, (state, { payload }) => {
      state.cocktailList = payload;
      state.isLoading = false;
    });
    addCase(fetchCocktailList.pending, (state) => {
      state.isLoading = true;
    });
    addCase(fetchCocktailList.rejected, (state) => {
      state.isLoading = false;
    });

    // Create New Cocktails
    addCase(createNewCocktail.fulfilled, (state) => {
      state.isLoading = false;
    });
    addCase(createNewCocktail.pending, (state) => {
      state.isLoading = true;
    });
    addCase(createNewCocktail.rejected, (state) => {
      state.isLoading = false;
    });

    // Update Cocktail
    addCase(updateCocktail.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    addCase(updateCocktail.pending, (state) => {
      state.isLoading = true;
    });
    addCase(updateCocktail.rejected, (state) => {
      state.isLoading = false;
    });

    // Fetch Bar and Member Info
    addCase(fetchBarAndMemberInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (!!payload.member) {
        state.member.id = payload.member.id;
        state.member.name = payload.member.user.name;
        state.member.email = payload.member.user.email;
        state.member.permissions = payload.member.permissions;
        state.barName = payload.member.parentBar.name;
        state.barProfile = payload.member.parentBar;
        state.team = payload.team;
      }
    });
    addCase(fetchBarAndMemberInfo.pending, (state) => {
      state.isLoading = true;
    });
    addCase(fetchBarAndMemberInfo.rejected, (state) => {
      state.isLoading = false;
    });

    // Fetch Team
    addCase(fetchTeamMembers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (!!payload.error) {
        state.team = payload.members;
      }
    });
    addCase(fetchTeamMembers.pending, (state) => {
      state.isLoading = true;
    });
    addCase(fetchTeamMembers.rejected, (state) => {
      state.isLoading = false;
    });

    // Delete Bar and associated cocktails and members
    addCase(deleteBar.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    addCase(deleteBar.pending, (state) => {
      state.isLoading = true;
    });
    addCase(deleteBar.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

// export const {
//   // Reducer Exports
// } = barSlice.actions;

export const getCocktailList = (state) => state.bar.cocktailList;
export const getCocktailById = (state, id) => {
  return state.bar.cocktailList.find((cocktail) => cocktail.id === id);
};

export const getTeamMemberList = (state) => state.bar.team;
export const getTeamMemberById = (state, id) => {
  return state.bar.team.find((member) => member.id === id);
};

export const getBarProfile = (state) => {
  return state.bar.barProfile;
};

export const hasAdminCredentials = (state) => {
  return ["owner", "super_admin", "admin"].includes(
    state.bar.member.permissions
  );
};

export default barSlice.reducer;
