import { useSelector } from "react-redux";
import { getBarProfile } from "./barSlice";

const BarProfile = () => {
  const { name, description } = useSelector(getBarProfile);

  return (
    <>
      <h1>{name}</h1>
      <br />
      <h4>About Us:</h4>
      <p>{description}</p>
    </>
  );
};

export default BarProfile;
