import { Link } from "react-router-dom";
import "./user.css";

const BarList = ({ userBarList }) => {
  const barListContent =
    userBarList?.length < 1 ? (
      <h1>No bars to display.</h1>
    ) : (
      userBarList?.map(({ parentBar }) => {
        return (
          <Link
            to={`/${parentBar?.id}/cocktail-list`}
            key={parentBar?.id}
            className="bar-link"
          >
            <div className="bar-list_item">{parentBar?.name}</div>
          </Link>
        );
      })
    );

  return (
    <div className="bar-list-page">
      <div className="new-bar-link">
        <Link to="/user/new-bar">+ Create New Bar</Link>
      </div>
      <div className="bar-list">{barListContent}</div>
    </div>
  );
};

export default BarList;
