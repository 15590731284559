import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCocktailList, hasAdminCredentials } from "./barSlice";

const CocktailList = () => {
  const { barId } = useParams();

  const cocktailList = useSelector(getCocktailList);
  const isAdmin = useSelector(hasAdminCredentials);

  const cocktailListContent = cocktailList?.map((cocktail) => {
    return (
      <Link
        to={`/${barId}/cocktail-list/${cocktail.id}`}
        key={cocktail.id}
        className="bar-link"
      >
        <div className="bar-list_item">{cocktail.name}</div>
      </Link>
    );
  });

  return (
    <>
      {isAdmin && (
        <div className="new-bar-link">
          <Link
            to={`/${barId}/cocktail-list/new-cocktail`}
            // className="new-bar-link"
          >
            + Add New Cocktail
          </Link>
        </div>
      )}
      <div>{cocktailList ? cocktailListContent : <h2>Loading...</h2>}</div>
    </>
  );
};

export default CocktailList;
