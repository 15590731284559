import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setBarList } from "../user/userSlice";
import Axios from "../../lib/Axios";

const TOKEN_KEY = process.env.REACT_APP_TOKEN_HEADER_KEY;

const initialState = {
  user: "",
  isLoading: false,
  // TODO: Set asyncThunk to populate error message
  errorMessage: "",
};

export const logIn = createAsyncThunk(
  "auth/logIn",
  async (loginCredentials, { dispatch }) => {
    try {
      const { data } = await Axios.post("/users/login", loginCredentials);

      // TODO: Handle login error when backend not running or connected.
      if (!!data.user) {
        dispatch(setBarList(data.userBarsList));

        return { user: data.user, token: data.token };
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (newUserInfo) => {
    try {
      const { data } = await Axios.post("/users/register", newUserInfo);

      if (!!data.user) {
        return { user: data.user, token: data.token };
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const refreshCredentials = createAsyncThunk(
  "auth/refreshCredentials",
  async (localToken, { dispatch }) => {
    try {
      const { data } = await Axios.get("users/verify-user", {
        headers: { token: localToken },
      });
      // TODO: Set up error handling
      // if (data.error) {
      //   localStorage.removeItem(TOKEN_KEY);
      // }

      dispatch(setBarList(data.userBarsList));

      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state = initialState;
      localStorage.removeItem(TOKEN_KEY);
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(logIn.fulfilled, (state, action) => {
      if (action.payload.user) {
        state.user = action.payload.user;
        localStorage.setItem(TOKEN_KEY, action.payload.token);
      }
      state.isLoading = false;
    });
    builder.addCase(logIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logIn.rejected, (state) => {
      state.isLoading = false;
    });

    // Register
    builder.addCase(register.fulfilled, (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem(TOKEN_KEY, action.payload.token);
      state.isLoading = false;
    });
    builder.addCase(register.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(register.rejected, (state) => {
      state.isLoading = false;
    });

    // Refresh user credentials
    builder.addCase(refreshCredentials.fulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.isLoading = false;
    });
    builder.addCase(refreshCredentials.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(refreshCredentials.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { logOut } = authSlice.actions;

export const getAuthCredentials = (state) => state.auth;

export default authSlice.reducer;
