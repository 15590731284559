import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createNewCocktail, updateCocktail } from "./barSlice";

const CocktailForm = ({ cocktailDetails, toggleEdit, setToggleEdit }) => {
  const { barId, cocktailId } = useParams();

  const formReset = {
    parentBar: barId,
    name: "",
    glass: "",
    ingredients: "",
    prep: "",
    garnish: "",
  };

  const [newCocktail, setNewCocktail] = useState(cocktailDetails || formReset);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrorMessage("");

    setNewCocktail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!!cocktailDetails) {
      dispatch(updateCocktail({ cocktailId, cocktailData: newCocktail }));
      navigate(`../cocktail-list/${cocktailId}`);
    } else {
      dispatch(createNewCocktail(newCocktail));
      setNewCocktail(formReset);
      navigate("../cocktail-list");
    }

    window.location.reload(true);
  };

  const glassOptions = [
    "Old Fashioned",
    "Rocks",
    "Highball",
    "Martini",
    "Coupe",
    "Wine",
    "Champagne Flute",
    "Beer Pint",
    "Shot",
  ];

  return (
    <form className="new-bar-form" onSubmit={handleSubmit}>
      <h2 className="new-bar-form-title">
        {!!cocktailDetails ? "Edit" : "New"} Cocktail
      </h2>
      <label htmlFor="name">
        Name:
        <br />
        <input
          required
          type="text"
          name="name"
          id="name"
          value={newCocktail.name}
          onChange={(e) => handleChange(e)}
          autoComplete="off"
        />
        <div className="new-bar-error-message">{errorMessage}</div>
      </label>

      <label htmlFor="glass">
        Glass: <br />
        <select
          value={newCocktail.glass}
          onChange={(e) => handleChange(e)}
          name="glass"
        >
          <option value="">-- Select --</option>
          {glassOptions.map((option) => {
            return (
              <option value={option} key={option}>
                {option}
              </option>
            );
          })}
        </select>
      </label>

      <label htmlFor="ingredients">
        Ingredients: <br />
        <textarea
          required
          name="ingredients"
          id="ingredients"
          value={newCocktail.ingredients}
          onChange={(e) => handleChange(e)}
        ></textarea>
      </label>

      <label htmlFor="prep">
        Preparation: <br />
        <textarea
          required
          name="prep"
          id="prep"
          value={newCocktail.prep}
          onChange={(e) => handleChange(e)}
        ></textarea>
      </label>

      <label htmlFor="garnish">
        Garnish:
        <br />
        <input
          required
          type="text"
          name="garnish"
          id="garnish"
          value={newCocktail.garnish}
          onChange={(e) => handleChange(e)}
          autoComplete="off"
        />
        <div className="new-bar-error-message">{errorMessage}</div>
      </label>

      {!!cocktailDetails ? (
        <>
          <button type="submit">Save</button>
          <button onClick={() => setToggleEdit(!toggleEdit)}>Discard</button>
        </>
      ) : (
        <button type="submit" className="new-bar-submit">
          Create
        </button>
      )}
    </form>
  );
};

export default CocktailForm;
