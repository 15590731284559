import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createNewBar } from "./userSlice";

import "./user.css";

const NewBarForm = () => {
  const userId = useSelector((state) => state.auth.user.id);

  const formReset = {
    name: "",
    owner: userId,
    description: "",
  };

  const [newBar, setNewBar] = useState(formReset);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrorMessage("");

    setNewBar((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(createNewBar(newBar));

    setNewBar(formReset);
    navigate("/user/bar-list");
    document.location.reload(true);
  };

  if (!userId) return <h1>Loading...</h1>;

  return (
    <div className="page-content">
      <form className="new-bar-form" onSubmit={handleSubmit}>
        <h2 className="new-bar-form-title">Create New Bar</h2>
        <label htmlFor="name">
          Name:
          <br />
          <input
            required
            type="text"
            name="name"
            id="name"
            value={newBar.name}
            onChange={(e) => handleChange(e)}
            autoComplete="off"
          />
          <div className="new-bar-error-message">{errorMessage}</div>
        </label>

        <label htmlFor="description">
          Description: <br />
          <textarea
            required
            name="description"
            id="description"
            value={newBar.description}
            onChange={(e) => handleChange(e)}
          ></textarea>
        </label>

        <button type="submit" className="new-bar-submit">
          Create
        </button>
      </form>
    </div>
  );
};

export default NewBarForm;
