import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCocktailById, hasAdminCredentials } from "./barSlice";
import CocktailForm from "./CocktailForm";

const CocktailDetail = () => {
  const [toggleEdit, setToggleEdit] = useState(false);

  const { barId, cocktailId } = useParams();

  const cocktail = useSelector((state) => getCocktailById(state, cocktailId));
  const isAdmin = useSelector(hasAdminCredentials);

  if (!cocktail) return <h1>Loading...</h1>;

  return (
    <>
      {!toggleEdit ? (
        <>
          <div className="cocktail-detail-card">
            {/* <div className="new-bar-form"> */}
            <h1 className="cocktail-name new-bar-form-title">
              {cocktail?.name}
            </h1>
            <h4>Glassware:</h4>
            <p>{cocktail?.glass}</p>
            <h4>Ingredients:</h4>
            <p>{cocktail?.ingredients}</p>
            <h4>Prep:</h4>
            <p>{cocktail?.prep}</p>
            <h4>Garnish:</h4>
            <p>{cocktail?.garnish}</p>
            {isAdmin && (
              <button onClick={() => setToggleEdit(!toggleEdit)}>Edit</button>
            )}
          </div>
        </>
      ) : (
        <CocktailForm
          cocktailDetails={{
            parentBar: barId,
            name: cocktail?.name,
            glass: cocktail?.glass,
            ingredients: cocktail?.ingredients,
            prep: cocktail?.prep,
            garnish: cocktail?.garnish,
          }}
          toggleEdit={toggleEdit}
          setToggleEdit={setToggleEdit}
        />
      )}
    </>
  );
};

export default CocktailDetail;
