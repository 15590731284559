import { useSelector } from "react-redux";

const MyTeamMemberProfile = () => {
  const { name, email, permissions } = useSelector((state) => state.bar.member);

  return (
    <>
      <h1>{name}</h1>
      <p>Permissions: {permissions}</p>
      <p>Email: {email}</p>
    </>
  );
};

export default MyTeamMemberProfile;
