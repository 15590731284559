import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTeamMemberList, hasAdminCredentials } from "./barSlice";

const TeamList = () => {
  const { barId } = useParams();

  const memberList = useSelector(getTeamMemberList);
  const isAdmin = useSelector(hasAdminCredentials);

  const memberListContent = memberList.length ? (
    memberList.map((member) => {
      return (
        <Link
          to={`/${barId}/team/${member.id}`}
          key={member.id}
          className="bar-link"
        >
          <div className="bar-list_item">{member.user.name}</div>
        </Link>
      );
    })
  ) : (
    <h1>Add members to your team!</h1>
  );

  return (
    <div>
      {isAdmin && (
        <div className="new-bar-link">
          <Link to={`/${barId}/team/add-member`} className="new-bar-link">
            + Add New Member
          </Link>
        </div>
      )}
      {memberListContent}
    </div>
  );
};

export default TeamList;
