import { useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { register } from "./authSlice";

import "./auth.css";

const Register = ({ isAuthenticated }) => {
  const valuesReset = {
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
  };

  const [newUser, setNewUser] = useState(valuesReset);
  // TODO: Set error messages through redux / asyncThunk
  const [error, setError] = useState("");
  const [message, setMessage] = useState(valuesReset);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    setMessage(valuesReset);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newUser.password !== newUser.repeatPassword) {
      setMessage((prev) => ({
        ...message,
        password: "No match!",
        repeatPassword: "No match!",
      }));
      return;
    }

    dispatch(
      register({
        name: newUser.name,
        email: newUser.email,
        password: newUser.password,
      })
    );
    setNewUser(valuesReset);
    navigate("/user/bar-list");
  };

  if (isAuthenticated) return <Navigate replace to="/user/bar-list" />;

  return (
    <div className="sign-up-card">
      <form
        className="sign-up-form"
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
      >
        <div className="title">REGISTER</div>
        <label htmlFor="name">
          Name<span className="name message"></span>
        </label>

        <input
          type="text"
          name="name"
          id="name"
          value={newUser.name}
          onChange={handleChange}
          required
        />
        <label htmlFor="email">
          Email
          <span className="input-message">{message.email}</span>
        </label>
        <input
          type="text"
          name="email"
          id="email"
          value={newUser.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="password">
          Password
          <span className="input-message">{message.password}</span>
        </label>
        <input
          type="password"
          name="password"
          id="password"
          value={newUser.password}
          onChange={handleChange}
          required
        />
        <label htmlFor="repeatPassword">
          Retype Password
          <span className="input-message">{message.repeatPassword}</span>
        </label>
        <input
          type="password"
          name="repeatPassword"
          id="repeatPassword"
          value={newUser.repeatPassword}
          onChange={handleChange}
          required
        />
        <div className="error-message">{error}</div>
        <button className="submit-button" type="submit">
          Sign Up
        </button>
        <p className="login-signup-link">
          Already a user? <Link to="../login">LOGIN</Link>
        </p>
      </form>
    </div>
  );
};

export default Register;
