import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { fetchTeamMembers } from "./barSlice.js";
import Axios from "../../lib/Axios.js";

// ---- Helper Functions ------
const findUserByEmail = async (email) => {
  const { data } = await Axios.get(`users/find/?email=${email}`);

  return data;
};

const postNewMember = async (barId, newMemberInfoObj) => {
  const { data } = await Axios.post(`bar/${barId}/members/`, newMemberInfoObj);
  return data;
};
// ---- Helper Functions -----

const AddMember = () => {
  const [query, setQuery] = useState("");
  const [user, setUser] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState(false);
  const [newMember, setNewMember] = useState({
    parentBar: "",
    user: "",
    permissions: "user",
    title: "",
  });

  const { barId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleQuerySubmit = async (e) => {
    e.preventDefault();

    const foundUser = await findUserByEmail(query);
    if (!foundUser) setNotFoundMessage(true);
    console.log("Found User: ", foundUser);

    setUser(foundUser);
    setNewMember((prev) => ({
      ...prev,
      parentBar: barId,
      user: foundUser?._id,
    }));
    setQuery("");
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    setNotFoundMessage(false);
  };

  const handleNewMemberSubmit = async (e) => {
    e.preventDefault();

    const newMemberSubmitted = await postNewMember(barId, newMember);
    console.log(
      "Returned New Member Obj, from AddMember: ",
      newMemberSubmitted,
      "Exists: ",
      !!newMemberSubmitted.error
    );

    dispatch(fetchTeamMembers(barId));
    navigate("../team");
    document.location.reload(true);
  };

  const handleNewMemberChange = (e) => {
    const { name, value } = e.target;
    setNewMember((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <br />
      {!user ? (
        <form onSubmit={handleQuerySubmit}>
          <h1>Find user by email:</h1>
          <input
            type="text"
            value={query}
            onChange={handleQueryChange}
            placeholder="email@example.com"
          />
          {notFoundMessage && <p>No user found with that email address.</p>}
        </form>
      ) : (
        <form onSubmit={handleNewMemberSubmit}>
          <Link onClick={() => setUser(null)}>Back to Find User</Link>

          <h2>{user?.name}</h2>
          <p>{user?.email}</p>

          <label htmlFor="permissions">
            Permissions:
            <select
              name="permissions"
              id="permissions"
              value={newMember.permissions}
              onChange={handleNewMemberChange}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </label>
          <br />
          <label htmlFor="title">
            Position:
            <input type="text" name="title" onChange={handleNewMemberChange} />
          </label>
          <br />
          <br />
          <button type="submit">Add Member</button>
        </form>
      )}
    </>
  );
};
export default AddMember;
