import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import Axios from "../../lib/Axios";

const initialState = {
  user: null,
  barList: [],
  isLoading: false,
};

export const fetchBarList = createAsyncThunk(
  "user/fetchBarList",
  async (userId) => {
    try {
      const { data } = await Axios.get(`/users/${userId}/bars`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createNewBar = createAsyncThunk(
  "user/createNewBar",
  async (newBarData) => {
    try {
      await Axios.post("/bar", newBarData);
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setBarList: (state, action) => {
      state.barList = action.payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchBarList.fulfilled, (state, { payload }) => {
      state.barList = payload;
    });
    addCase(createNewBar.fulfilled, (state) => {
      state.isLoading = false;
    });
    addCase(createNewBar.pending, (state) => {
      state.isLoading = true;
    });
    addCase(createNewBar.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setBarList } = userSlice.actions;

export const getBarList = (state) => state.user.barList;

export default userSlice.reducer;
