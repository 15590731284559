import { useSelector } from "react-redux";

const Profile = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <br />
      <br />
      <h1>{user.name}</h1>
      <p>{user.email}</p>
    </>
  );
};

export default Profile;
