import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTeamMemberById } from "./barSlice";

const TeamMemberDetail = () => {
  const { teamMemberId } = useParams();

  const {
    user: { name, email },
    permissions,
  } = useSelector((state) => getTeamMemberById(state, teamMemberId));

  return (
    <div className="cocktail-detail-card">
      <h1 className="cocktail-name new-bar-form-title">Name: {name}</h1>
      {/* <h4>Position:</h4>
      <p>{position}</p> */}
      <p>Permissions: {permissions}</p>
      <h4>Email:</h4>
      <p>{email}</p>
      {/* <h4>Bio:</h4> */}
      {/* <p>{memberBio}</p> */}
    </div>
  );
};

export default TeamMemberDetail;
