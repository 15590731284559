import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { refreshCredentials } from "./features/auth/authSlice";

import AppLayout from "./components/AppLayout";

import Home from "./pages/Home";

import Login from "./features/auth/Login";
import Register from "./features/auth/Register";

import AuthRequired from "./features/auth/AuthRequired";

import BarList from "./features/user/BarList";
import NewBarForm from "./features/user/NewBarForm";
import Profile from "./features/user/Profile";

import BarLayout from "./components/BarLayout";
import BarProfile from "./features/bar/BarProfile";
import CocktailList from "./features/bar/CocktailList";
import CocktailDetail from "./features/bar/CocktailDetail";
import CocktailForm from "./features/bar/CocktailForm";
import TeamList from "./features/bar/TeamList";
import AddMember from "./features/bar/AddMember";
import TeamMemberDetail from "./features/bar/TeamMemberDetail";
import MyTeamMemberProfile from "./features/bar/MyTeamMemberProfile";

import "./App.css";

const LOCAL_TOKEN = localStorage.getItem(
  process.env.REACT_APP_TOKEN_HEADER_KEY
);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (LOCAL_TOKEN) {
      dispatch(refreshCredentials(LOCAL_TOKEN));
    }
  }, [dispatch]);

  const { user, isLoading } = useSelector((state) => state.auth);
  const userBarList = useSelector((state) => state.user.barList);

  const isAuthenticated = !!user;

  if (isLoading) return <h1>Loading...</h1>;

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Home />} />
        <Route
          path="/login"
          element={<Login isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/register"
          element={<Register isAuthenticated={isAuthenticated} />}
        />

        <Route
          element={
            isLoading ? (
              <h1>Loading...</h1>
            ) : (
              <AuthRequired
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
              />
            )
          }
        >
          <Route path="/user">
            <Route
              path="bar-list"
              index
              element={<BarList userBarList={userBarList} />}
            />
            <Route path="new-bar" element={<NewBarForm />} />
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route path="/:barId" element={<BarLayout userId={user?.id} />}>
            <Route path="bar-profile" index element={<BarProfile />} />
            <Route path="cocktail-list" element={<CocktailList />} />
            <Route
              path="cocktail-list/new-cocktail"
              element={<CocktailForm />}
            />
            <Route
              path="cocktail-list/:cocktailId"
              element={<CocktailDetail />}
            />
            <Route path="team" element={<TeamList />} />
            <Route path="team/add-member" element={<AddMember />} />
            <Route path="team/:teamMemberId" element={<TeamMemberDetail />} />
            <Route path="my-member-profile" element={<MyTeamMemberProfile />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
