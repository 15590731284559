import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut, getAuthCredentials } from "../features/auth/authSlice";

const TopNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authCredentials = useSelector(getAuthCredentials);

  const handleLogOut = () => {
    dispatch(logOut());
    navigate("/");
    document.location.reload(true);
  };

  return (
    <nav className="top-nav">
      <NavLink to="/" className="home-link">
        MY MENU
      </NavLink>
      {authCredentials.user ? (
        <>
          <NavLink to="/user/bar-list">My Bars</NavLink>
          <NavLink to="user/profile">Profile</NavLink>
          <NavLink to="/" onClick={handleLogOut}>
            Logout
          </NavLink>
        </>
      ) : (
        <>
          <NavLink to="/login">Login</NavLink>
          <NavLink to="/register">Register</NavLink>
        </>
      )}
    </nav>
  );
};

export default TopNav;
