import { useEffect } from "react";
import { Outlet, NavLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCocktailList,
  deleteBar,
  fetchBarAndMemberInfo,
} from "../features/bar/barSlice";
import { fetchBarList } from "../features/user/userSlice";

import "../features/bar/bar.css";

const BarLayout = ({ userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { barId } = useParams();

  useEffect(() => {
    dispatch(fetchCocktailList(barId));
    dispatch(fetchBarAndMemberInfo({ barId, userId }));
  }, []);

  const selectedBar = useSelector((state) => {
    return state.bar;
  });

  const userPermissions = useSelector((state) => state.bar.member?.permissions);
  const memberId = useSelector((state) => state.bar.member?.id);

  const handleDeleteBar = (barIdToDelete, memberDoingTheDeleting) => {
    dispatch(
      deleteBar({ barId: barIdToDelete, memberId: memberDoingTheDeleting })
    );
    dispatch(fetchBarList(userId));

    navigate("/user/bar-list");
    document.location.reload(true);
  };

  if (!selectedBar.barName || selectedBar.isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="bar-page">
      <nav className="side-nav">
        <div className="profile-link">
          <NavLink to={`bar-profile`}>
            <div>
              <span className="bar-name">{selectedBar.barName}</span>
              <br />
              <span className="display-type">Bar</span>
            </div>
          </NavLink>
        </div>
        <ul>
          <li>
            <NavLink to="cocktail-list">Cocktails</NavLink>
          </li>
          <li>
            <NavLink to="team">Members</NavLink>
          </li>
          <li className="li-last">
            <NavLink to="my-member-profile">My Team Profile</NavLink>
          </li>
          <li>
            {userPermissions === "owner" && (
              <button onClick={() => handleDeleteBar(barId, memberId)}>
                Delete
              </button>
            )}
          </li>
        </ul>
      </nav>
      <section className="page-content">
        <Outlet />
      </section>
    </div>
  );
};

export default BarLayout;
