import { Outlet } from "react-router-dom";
import TopNav from "./TopNav";

import "./components.css";

const AppLayout = () => {
  return (
    <div className="App">
      <header>
        <TopNav />
      </header>
      <section className="main-content">
        <Outlet />
      </section>
    </div>
  );
};

export default AppLayout;
