// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-nav {
  position: fixed;
  z-index: 2000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 1rem 1.5rem;
  border-bottom: .125rem solid black;
  background-color: #222328;
  background: linear-gradient(90deg, var(--purple-drk) 5%, black 100%);
}

.top-nav a {
  color: #fff !important;
  text-decoration: none;
}

.home-link {
  margin: 0 auto 0 1em;
  padding: 0;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.5em;
}

.main-content {
  width: 100%;
  height: 100vh;
  padding-top: 4.7em;
  margin: auto;
}

.bar-list_item {
  font-size: 1.5em;
  color: var(--purple-mid);
  font-weight: bold;
  padding: 1em;
  margin-bottom: .5em;
  border-radius: 10px;
  background-color: #fff;
}

.bar-link {
  text-decoration: none;
}

@media (min-width: 800px) {
  .home-link {
    font-size: 2em;
  }

}

.active-link {
  background-color: var(--purple-drk);
}`, "",{"version":3,"sources":["webpack://./src/components/components.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,oBAAoB;EACpB,kCAAkC;EAClC,yBAAyB;EACzB,oEAAoE;AACtE;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,UAAU;EACV,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,cAAc;EAChB;;AAEF;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".top-nav {\n  position: fixed;\n  z-index: 2000;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 2rem;\n  width: 100%;\n  padding: 1rem 1.5rem;\n  border-bottom: .125rem solid black;\n  background-color: #222328;\n  background: linear-gradient(90deg, var(--purple-drk) 5%, black 100%);\n}\n\n.top-nav a {\n  color: #fff !important;\n  text-decoration: none;\n}\n\n.home-link {\n  margin: 0 auto 0 1em;\n  padding: 0;\n  font-family: 'Bebas Neue', sans-serif;\n  font-size: 1.5em;\n}\n\n.main-content {\n  width: 100%;\n  height: 100vh;\n  padding-top: 4.7em;\n  margin: auto;\n}\n\n.bar-list_item {\n  font-size: 1.5em;\n  color: var(--purple-mid);\n  font-weight: bold;\n  padding: 1em;\n  margin-bottom: .5em;\n  border-radius: 10px;\n  background-color: #fff;\n}\n\n.bar-link {\n  text-decoration: none;\n}\n\n@media (min-width: 800px) {\n  .home-link {\n    font-size: 2em;\n  }\n\n}\n\n.active-link {\n  background-color: var(--purple-drk);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
