import axios from "axios";

const LOCAL_TOKEN = localStorage.getItem(
  process.env.REACT_APP_TOKEN_HEADER_KEY
);

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "User-Token": LOCAL_TOKEN,
  },
});

export default Axios;
